import classNames from 'classnames';
import { TransportLink } from 'components/1-atoms/Links';
import { ContentCard } from 'components/2-molecules/Card';
import styles from './TransportLinks.module.scss';
export type TransportNavigationLink = {
	link: React.ReactElement;
	title: string;
	text: string;
	highlighted: boolean;
};
export interface TransportLinksProps {
	heading: string;
	headingLevel: 'h1' | 'h2';
	richText?: React.ReactNode;
	links: TransportNavigationLink[];
	noTopMargin?: boolean;
}

export const TransportLinks: React.FC<TransportLinksProps> = ({ heading, headingLevel, richText, links, noTopMargin }) => (
	<div className={classNames(styles.TransportLinks, noTopMargin && styles.TransportLinks___noTopMargin)}>
		{heading ? <ContentCard heading={heading} headingLevel={headingLevel} richText={richText} /> : null}

		<div className={styles.TransportLinks_assets}>
			{links.map((link, i) => (
				<TransportLink key={i} {...link} />
			))}
		</div>
	</div>
);
