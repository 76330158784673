import { usePageContext } from 'application/contexts/PageContext';
import { ContentPage } from 'designsystem/components/5-templates/ContentPage';
import { SingleContentSectionFeature } from 'features/Modules/ContentSection/SingleContentSectionFeature';
import { ModuleFeature } from 'features/Modules/DynamicModule';
import { TopBannerFeature } from 'features/Modules/TopBanner';
import { TransportLinksFeature } from 'features/Modules/TransportLinks';

export const TransportPageFeature: React.FC<Content.TransportPage> = ({ properties, childPages, additionalTop, additionalPageContent }) => {
	const { pageSection, pageSections, bannerContent } = properties ?? {};
	const pageContext = usePageContext();
	properties.heading = properties.heading || pageContext.name;

	return (
		<ContentPage
			additionalTop={additionalTop}
			topBanner={bannerContent?.length > 0 && <TopBannerFeature bannerContent={bannerContent} />}
			additionalPageContent={additionalPageContent}
		>
			<TransportLinksFeature
				content={{ properties: properties }}
				childPages={childPages}
				noTopMargin={pageContext.pageLanguageVersions.length > 1 ? true : false}
				headingLevel="h1"
			/>

			<SingleContentSectionFeature pageSection={pageSection} />
			{pageSections?.map((sectionModule, index) => (
				<ModuleFeature key={index} contentModule={sectionModule} />
			))}
		</ContentPage>
	);
};
