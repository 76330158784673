import { usePageContext } from 'application/contexts/PageContext';
import { TransportLinks, TransportNavigationLink } from 'designsystem/components/3-organisms/TransportLinks';
import { LinkFeature } from 'features/Modules/Link';
import { RichTextFeature } from '../RichText';

export interface TransportLinksFeatureProps extends Content.TransportLinks {
	noTopMargin?: boolean;
}

export const TransportLinksFeature: React.FC<TransportLinksFeatureProps> = ({ content, settings, childPages, headingLevel = 'h2', noTopMargin }) => {
	noTopMargin = noTopMargin || settings?.properties?.noTopMargin || false;
	const { heading, richText, text, transportLinkItems } = content.properties ?? {};
	const pageContext = usePageContext();

	const transportNaviationLinks: Navigation.NavigationItem[] = transportLinkItems?.map((item, index) => {
		const { description } = item?.content?.properties ?? {};
		const { highlighted } = item?.settings?.properties ?? {};
		const { name, url, target } = item?.content?.properties?.link ?? {};
		const transportNavigationLink: Navigation.NavigationItem = {
			id: `transportlink_${name}_${index}`,
			name: name,
			url: url,
			target: target,
			link: <LinkFeature url={url} target={target} />,
			description: description,
			highlighted: highlighted,
		};
		return transportNavigationLink;
	});

	const childTransportNavigationLinks: Navigation.NavigationItem[] = (childPages ?? pageContext.childPages)?.map((page) => {
		return {
			...page,
			hidden: page.properties.umbracoNaviHide,
			title: page.properties.navigationTitle ?? page.name,
			description: page.properties.navigationDescription,
			highlighted: page.properties.highlighted,
			link: <LinkFeature url={page.url} name={page.name} />,
		};
	});

	const transportNavigation = transportNaviationLinks
		? childTransportNavigationLinks
			? childTransportNavigationLinks.concat(transportNaviationLinks)
			: transportNaviationLinks
		: childTransportNavigationLinks;

	const filteredTransportNavigation = transportNavigation.filter((tn) => !tn.hidden);

	const transportNavigationWithFallback = filteredTransportNavigation?.map((item) => {
		const { description, title, name, link, highlighted } = item ?? {};
		return {
			text: description,
			title: title ? title : name,
			link: link,
			highlighted: highlighted,
		} as TransportNavigationLink;
	});
	const textAsRichtext = {
		properties: {
			content: text,
		},
	};
	return (
		<TransportLinks
			heading={heading}
			headingLevel={headingLevel}
			richText={<RichTextFeature content={richText[0] ? richText[0].content : textAsRichtext} />}
			links={transportNavigationWithFallback}
			noTopMargin={noTopMargin}
		/>
	);
};
